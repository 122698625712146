<template>
  <el-select
    v-model="resellerServiceModel"
    @change="resellerServiceChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.RESELLER_SERVICE')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getResellerServices"
    :loading="loading"
  >
    <el-option
      v-if="showAll"
      :value="null"
      :label="$t('COMMON.RESELLER_SERVICE')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="resellerService in resellerServices"
      :key="resellerService.id"
      :value="resellerService.id"
      :label="`${resellerService.code} - ${resellerService.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "purchases-order-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    resellerService: {
      type: String,
      default: null,
      description: "ResellerService id",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
  },

  data() {
    return {
      resellerServiceModel: this.resellerService,
      resellerServices: {},
    };
  },

  setup() {},

  created() {
    this.getResellerServices(null, this.resellerService);
  },

  methods: {
    async getResellerServices(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "-created_at",
          filter: {
            ...(query ? { code: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }

        await this.$store.dispatch("resellerServices/list", params);
        const resellerServicesArr = await this.$store.getters[
          "resellerServices/list"
        ];
        this.resellerServices = {};
        resellerServicesArr.forEach((resellerService) => {
          this.resellerServices[resellerService.id] = resellerService;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    resellerServiceChanged(resellerServiceId) {
      const resellerService = Object.values(this.resellerServices).find(
        (item) => item.id === resellerServiceId
      );
      this.$emit("resellerServiceChanged", resellerServiceId, resellerService);
    },
  },

  watch: {
    resellerService(resellerService) {
      if (resellerService) {
        this.resellerServiceModel = resellerService;
        if (resellerService !== this.resellerServiceModel) {
          this.getResellerServices(null, resellerService);
        }
      } else {
        this.resellerServiceModel = null;
      }
    },
    filterReseller() {
      this.getResellerServices();
    },
  },
};
</script>
