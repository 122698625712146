<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
    >
      <base-input
        :label="`${$t('COMMON.RESELLER')} (*)`"
        :placeholder="$t('COMMON.RESELLER')"
      >
        <reseller-selector
          :allowNone="true"
          :reseller="resellerInvoice.reseller.id"
          :filterable="true"
          :showAll="false"
          :disabled="!!resellerInvoice.id"
          @resellerChanged="
            (resellerId) => {
              resellerInvoice.reseller.id = resellerId;
              resellerInvoice.recipient.id = null;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.reseller" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('RESELLER_INVOICES.EXPIRATION_TIME')} (*)`"
        :placeholder="$t('RESELLER_INVOICES.EXPIRATION_TIME')"
      >
        <flat-picker
          :config="{
            allowInput: true,
            minDate: 'today',
            locale: $flatPickrLocale(),
          }"
          class="form-control datepicker"
          v-model="resellerInvoice.expiration_time"
          @on-change="
            () => {
              onFormChanged();
            }
          "
        >
        </flat-picker>
      </base-input>
      <validation-error :errors="apiValidationErrors.expiration_time" />
    </div>

    <div class="form-wrapper full">
      <base-input :label="`${$t(`RESELLER_INVOICES.SELECT_ORGANIZATION`)} (*)`">
        <organization-selector
          :allowNone="true"
          :organization="resellerInvoice.recipient.id"
          :filterReseller="resellerInvoice.reseller.id"
          :filterable="true"
          :showAll="false"
          :disabled="!!resellerInvoice.id"
          @organizationChanged="
            (organizationId, organization) => {
              resellerInvoice.recipient.id = organizationId;
              if (organization) {
                resellerInvoice.billing_entity_type =
                  organization.billing_entity_type;
                resellerInvoice.billing_firstname =
                  organization.billing_firstname;
                resellerInvoice.billing_lastname =
                  organization.billing_lastname;
                resellerInvoice.billing_company_name =
                  organization.billing_company_name;
                resellerInvoice.billing_email = organization.billing_email;
                resellerInvoice.billing_country = organization.billing_country;
                resellerInvoice.billing_state = organization.billing_state;
                resellerInvoice.billing_city = organization.billing_city;
                resellerInvoice.billing_zipcode = organization.billing_zipcode;
                resellerInvoice.billing_address = organization.billing_address;
              }
              onFormChanged();
            }
          "
        />
      </base-input>
    </div>

    <h2 class="col-12 mt-0">{{ $t("COMMON.NOTE") }}</h2>

    <div class="form-wrapper full">
      <html-editor v-model="resellerInvoice.excerpt" @change="onFormChanged()">
      </html-editor>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <h2 class="col-12 mt-0 mb-3">{{ $t("COMMON.BILLING_INFORMATIONS") }}</h2>

    <div class="form-wrapper full">
      <base-input
        :label="$t('COMMON.TYPE')"
        :placeholder="$t('COMMON.TYPE')"
        @change="onFormChanged"
      >
        <el-select name="Type" v-model="resellerInvoice.billing_entity_type">
          <el-option
            :value="BILLING_ENTITY_TYPE_INDIVIDUAL"
            :label="$t('COMMON.BILLING_ENTITY_TYPE_INDIVIDUAL')"
          />
          <el-option
            :value="BILLING_ENTITY_TYPE_COMPANY"
            :label="$t('COMMON.BILLING_ENTITY_TYPE_COMPANY')"
          />
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.billing_entity_type" />
    </div>

    <div
      class="form-group-wrapper"
      v-if="
        resellerInvoice.billing_entity_type == BILLING_ENTITY_TYPE_INDIVIDUAL
      "
    >
      <div class="form-group-wrapper-item">
        <base-input
          :label="$t('COMMON.FIRSTNAME')"
          v-model="resellerInvoice.billing_firstname"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_firstname" />
      </div>
      <div class="form-group-wrapper-item">
        <base-input
          :label="$t('COMMON.LASTNAME')"
          v-model="resellerInvoice.billing_lastname"
        />
        <validation-error :errors="apiValidationErrors.billing_lastname" />
      </div>
    </div>

    <div
      class="form-wrapper"
      v-if="resellerInvoice.billing_entity_type == BILLING_ENTITY_TYPE_COMPANY"
    >
      <base-input
        :label="$t('COMMON.COMPANY_NAME')"
        v-model="resellerInvoice.billing_company_name"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.billing_company_name" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="$t('COMMON.EMAIL')"
        v-model="resellerInvoice.billing_email"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.billing_email" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="$t('COMMON.COUNTRY')"
        :placeholder="$t('COMMON.COUNTRY')"
      >
        <country-selector
          :country="resellerInvoice.billing_country"
          :filterable="true"
          :showAll="false"
          @countryChanged="
            (country) => {
              resellerInvoice.billing_country = country;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.billing_country" />
    </div>

    <div class="form-wrapper">
      <base-input :label="$t('COMMON.STATE')" :placeholder="$t('COMMON.STATE')">
        <state-selector
          :country="resellerInvoice.billing_country"
          :state="resellerInvoice.billing_state"
          :filterable="true"
          :showAll="false"
          @stateChanged="
            (state) => {
              resellerInvoice.billing_state = state;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.billing_state" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="$t('COMMON.CITY')"
        v-model="resellerInvoice.billing_city"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.billing_city" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="$t('COMMON.ZIPCODE')"
        v-model="resellerInvoice.billing_zipcode"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.billing_zipcode" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="$t('COMMON.ADDRESS')"
        v-model="resellerInvoice.billing_address"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.billing_address" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          resellerInvoice.id
            ? $t("RESELLER_INVOICES.EDIT_RESELLER_INVOICE")
            : $t("RESELLER_INVOICES.ADD_RESELLER_INVOICE")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import formMixin from "@/mixins/form-mixin";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    ResellerSelector,
    flatPicker,
    HtmlEditor,
    OrganizationSelector,
    CountrySelector,
    StateSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["resellerInvoiceData", "formErrors", "loading"],

  data() {
    let resellerInvoiceData = { ...this.resellerInvoiceData };
    resellerInvoiceData = this.$fillUserResellerData(resellerInvoiceData);

    return {
      resellerInvoice: resellerInvoiceData,
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let resellerInvoiceData = cloneDeep(this.resellerInvoice);
      resellerInvoiceData.expiration_time = moment(
        resellerInvoiceData.expiration_time
      ).toISOString();
      resellerInvoiceData = this.$fillUserResellerData(resellerInvoiceData);
      if (!resellerInvoiceData.billing_company_name) {
        resellerInvoiceData.billing_company_name = "N/A";
      }
      if (!resellerInvoiceData.billing_firstname) {
        resellerInvoiceData.billing_firstname = "N/A";
      }
      if (!resellerInvoiceData.billing_lastname) {
        resellerInvoiceData.billing_lastname = "N/A";
      }
      this.$emit("resellerInvoiceSubmitted", resellerInvoiceData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    resellerInvoiceData(resellerInvoiceData) {
      resellerInvoiceData = this.$fillUserResellerData({
        ...resellerInvoiceData,
      });
      this.resellerInvoice = resellerInvoiceData;
    },
  },
};
</script>
