<template>
  <div class="container-fluid">
    <reseller-invoice-form
      :loading="loading"
      :resellerInvoiceData="resellerInvoice"
      :formErrors="formErrors"
      @resellerInvoiceSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultResellerInvoice from "../defaultResellerInvoice";
import ResellerInvoiceForm from "../partials/ResellerInvoiceForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ResellerInvoiceForm,
  },

  mixins: [alertLeave],

  data() {
    const resellerInvoice = cloneDeep(defaultResellerInvoice);
    resellerInvoice.taxes = cloneDeep(defaultTaxes);
    return {
      resellerInvoice: resellerInvoice,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(resellerInvoice) {
      this.loading = true;
      const resellerInvoiceData = cloneDeep(resellerInvoice);
      try {
        await this.$store.dispatch("resellerInvoices/add", resellerInvoiceData);
        this.$notify({
          type: "success",
          message: this.$t("RESELLER_INVOICES.RESELLER_INVOICE_ADDED"),
        });
        const resellerInvoice = await this.$store.getters[
          "resellerInvoices/resellerInvoice"
        ];
        this.$emit("onViewResellerInvoice", resellerInvoice, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
