<template>
  <el-select
    v-model="resellerModel"
    @change="resellerChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.RESELLER')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getResellers"
    :loading="loading"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL_RESELLERS')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="reseller in resellers"
      :key="reseller.id"
      :value="reseller.id"
      :label="`${reseller.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "reseller-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    reseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
  },

  data() {
    return {
      resellerModel: this.reseller,
      resellers: {},
    };
  },

  setup() {},

  created() {
    this.getResellers(null, this.reseller);
  },

  methods: {
    async getResellers(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        await this.$store.dispatch("resellers/list", params);
        const resellersArr = await this.$store.getters["resellers/list"];
        this.resellers = {};
        resellersArr.forEach((reseller) => {
          this.resellers[reseller.id] = reseller;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    resellerChanged(reseller) {
      this.$emit("resellerChanged", reseller);
    },
  },

  watch: {
    reseller(reseller) {
      if (reseller) {
        this.resellerModel = reseller;
        if (reseller !== this.resellerModel) {
          this.getResellers(null, reseller);
        }
      } else {
        this.resellerModel = null;
      }
    },
  },
};
</script>
